import { FC } from 'react';
import useTranslation from 'next-translate/useTranslation';

import { Match, ModuleScheduleSection, OddsDossierItem, Tag } from '@common/clients/api';
import { DateTimeUtil, Format } from '@common/utils/DateTimeUtil';
import { BettingDisclaimer, LogoVariant } from '@web/atoms/BettingDisclaimer';
import { BookmakerLogo } from '@web/atoms/BookmakerLogo';
import { Flexbox } from '@web/atoms/Flexbox';
import { ClubShortname } from '@web/organisms/MatchBlock/atoms/ClubShortname';

import { ClubThumbnail } from './atoms/ClubThumbnail';
import { OddsRow } from './molecules/OddsRow';

import styles from './MatchBlock.module.scss';

const isToday = (matchDate: Date): boolean => {
    const today = new Date();
    return (
        matchDate.getDate() === today.getDate() &&
        matchDate.getMonth() === today.getMonth() &&
        matchDate.getFullYear() === today.getFullYear()
    );
};

interface Props {
    tag?: Tag;
    items: OddsDossierItem[];
    matches?: Match[];
    trackerName: ModuleScheduleSection;
}

export const MatchBlock: FC<Props> = ({
    items = [],
    matches = [],
    trackerName = ModuleScheduleSection.ODDS_DOSSIER_HOME,
}) => {
    const __datetime = useTranslation('datetime').t;
    const hasDarkBackground = trackerName === ModuleScheduleSection.ODDS_DOSSIER_HOME;

    const match = !!matches.length ? matches[0] : undefined;
    const bookmaker = !!items.length ? items[0]?.bookmaker : undefined;
    const odds = !!match?.odds?.length ? match?.odds?.[0] : undefined;

    const url = match?.url || bookmaker?.url || '';
    const icon = hasDarkBackground ? bookmaker?.darkBackgroundIcon : bookmaker?.lightBackgroundIcon || '';

    const kickoff = new Date(match?.kickOff || new Date());
    const timeFormatted = DateTimeUtil.format(kickoff, Format.TIME_NUMERIC);
    const dateReadable = isToday(kickoff)
        ? __datetime('today')
        : DateTimeUtil.format(kickoff, Format.DATE_NUMERIC);

    const home = match?.home;
    const away = match?.away;

    return (
        <Flexbox
            column
            as="section"
            position="relative"
            classes={`${styles.MatchBlock} ${styles[trackerName]}`}
            align={'center'}
            justify={'start'}
        >
            <div className={styles['club-row']}>
                <ClubThumbnail classes={styles['row-item']} club={home} match={match} />
                <Flexbox column as={'div'} classes={styles['match-info']} position={'relative'}>
                    <h4 className={styles.time}>{timeFormatted}</h4>
                    <span className={styles.date}>{dateReadable}</span>
                </Flexbox>
                <ClubThumbnail classes={styles['row-item']} club={away} match={match} />
            </div>
            <div className={styles['team-row']}>
                <ClubShortname classes={styles['row-item']} club={home} match={match} />
                <BookmakerLogo
                    alt={bookmaker?.name || ''}
                    url={url || bookmaker?.url || ''}
                    src={icon || ''}
                    className={`${styles['betting-logo']} ${styles['row-item']}`}
                />
                <ClubShortname classes={styles['row-item']} club={away} match={match} />
            </div>
            {odds ? (
                <>
                    <ul className={styles['odds-row']}>
                        <OddsRow threeWayOdds={odds} />
                    </ul>
                    <div className={styles['disclaimer-row']}>
                        <BettingDisclaimer logoVariant={LogoVariant.TRANSPARENT} />
                    </div>
                </>
            ) : null}
        </Flexbox>
    );
};
