import { FC } from 'react';

import { Link } from '@common/atoms/Link';
import { ThreeWayOdds } from '@common/clients/api';

import styles from './BettingPill.module.scss';

interface Props {
    threeWayOdds: ThreeWayOdds;
    oddSymbol: '1' | 'X' | '2' | string;
    oddValue: string;
}

export const BettingPill: FC<Props> = ({ threeWayOdds, oddSymbol, oddValue }) => (
    <li className={`${styles.BettingPill} small`}>
        <Link href={threeWayOdds.url}>
            <a href={threeWayOdds.url} rel="sponsored nofollow" target="_blank">
                <span className={styles['threeway-symbol']}>{oddSymbol}</span>
                <span className={styles.odd}>{oddValue}</span>
            </a>
        </Link>
    </li>
);
