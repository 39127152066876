
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { FC } from 'react';
import dynamic from 'next/dynamic';
import { ParsedUrlQuery } from 'querystring';
import { FeaturedCarouselService, FeaturedItem, ModuleScheduleSection, PlatformID, } from '@common/clients/api';
import { ApiBaseRequest } from '@common/clients/request';
import { ContextData, defaultCatch } from '@common/defaults';
import { ItemsPerPage } from '@common/types';
import { PromiseHolder } from '@common/utils/PromiseHolder';
import { getDummyModule } from '@web/handlers/fetchActiveModules';
import { fetchHeadlinesDossier } from '@web/handlers/fetchHeadlinesDossier';
import { fetchHomepageNews } from '@web/handlers/fetchHomepageNews';
import { fetchVideoCarouselNews } from '@web/handlers/fetchVideoCarouselNews';
import { fetchOddsDossierIntro } from '@web/molecules/OddsDossierIntro/handlers';
import { PlatformToggle } from '@web/molecules/PlatformToggle';
import { getNextWebServerSidePropsWrapper, GetProps, GetPropsReturn, NextWebServerSideProps, Route, } from '@web/routing';
import { HomePage, Props, State } from '@web/templates/HomePage';
import { NewsListInsert } from '@web/templates/HomePage/organisms';
import { shouldInclude } from '@web/templates/HomePage/organisms/utils';
const ViHomePage = dynamic(() => import('@web/templates/HomePage/ViHomePage').then((mod) => mod.ViHomePage));
const fetchFeaturedCarrouselItems = async (contextData: ContextData, isClientSide: boolean): Promise<FeaturedItem[]> => {
    const request = new ApiBaseRequest(contextData, isClientSide);
    const featuredCarouselService = new FeaturedCarouselService(request);
    const response = await featuredCarouselService
        .getFeaturedCarouselByDomainId({
        contextId: contextData.context.id
    })
        .catch(defaultCatch);
    return response?.items || [];
};
const fetchOddsDossier = async (contextData: ContextData, query: ParsedUrlQuery) => {
    const platform = contextData.platform.id;
    if (!shouldInclude(platform, NewsListInsert.ODDS_DOSSIER))
        return undefined;
    const activeModules = await contextData.modulesBySection;
    const moduleScheduleItem = !activeModules?.[ModuleScheduleSection.ODDS_DOSSIER_HOME]?.[0] && query.betting === 'true'
        ? getDummyModule(ModuleScheduleSection.ODDS_DOSSIER_HOME)
        : activeModules?.[ModuleScheduleSection.ODDS_DOSSIER_HOME]?.[0];
    if (moduleScheduleItem || query.betting === 'true') {
        const oddsDossier = await fetchOddsDossierIntro(contextData, moduleScheduleItem, false);
        if (oddsDossier)
            return oddsDossier;
    }
    return undefined;
};
const prepareProps: GetProps<Props> = async (contextData, serverContext): GetPropsReturn<Props> => {
    const state: State = {
        news: [],
        currentPage: 1,
        hasNextPage: false
    };
    const props: PromiseHolder<Props> = {
        contextData,
        state: state,
        featuredItems: fetchFeaturedCarrouselItems(contextData, false),
        headlinesDossier: fetchHeadlinesDossier(contextData, false),
        oddsDossier: fetchOddsDossier(contextData, serverContext.query),
        videonews: []
    };
    const [news, videonews] = await Promise.all([
        fetchHomepageNews(contextData, state.currentPage, false, ItemsPerPage.HUGE),
        fetchVideoCarouselNews(contextData, state, false),
    ]);
    if (news?.data) {
        state.news = news.data;
        state.hasNextPage = news.pagination?.hasNextPage || false;
    }
    if (videonews?.data)
        props.videonews = videonews.data;
    return props;
};
const getServerSideProps: NextWebServerSideProps<Props> = getNextWebServerSidePropsWrapper(prepareProps, Route.Homepage);
export const Home: FC<Props> = (props) => {
    return (<>
            <PlatformToggle include={PlatformID.VI}>
                <ViHomePage {...props}/>
            </PlatformToggle>
            <PlatformToggle exclude={PlatformID.VI}>
                <HomePage {...props}/>
            </PlatformToggle>
        </>);
};
export default Home;

    async function __Next_Translate__getServerSideProps__1922e15df48__(ctx) {
      const res = await getServerSideProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getServerSideProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
          }))
        }
      }
    }
    export { __Next_Translate__getServerSideProps__1922e15df48__ as getServerSideProps }
  