import { FC } from 'react';

import { Match, Team } from '@common/clients/api';
import { InternalLink, Route } from '@web/atoms/InternalLink';

import styles from './ClubShortname.module.scss';

interface Props {
    classes?: string;
    club?: Team;
    match?: Match;
}

export const ClubShortname: FC<Props> = ({ classes, club, match }) => (
    <InternalLink
        route={club?.tag?.tagSlug ? Route.Club : Route.Match}
        query={club?.tag?.tagSlug ? { tag: club?.tag?.tagSlug } : { id: match?.id }}
        className={`${styles.ClubShortname} ${classes || ''}`}
    >
        <h4 className={'inverted'}>{club?.shortName || club?.name || ''}</h4>
    </InternalLink>
);
